import React from 'react';
import App from './app/App';
import './generated.css';
import './index.css';
import ReactDOM, { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// const rootElement = document.getElementById('root');
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <React.StrictMode>
//         <App />
//       </React.StrictMode>
//     </BrowserRouter>,
//     rootElement,
//   );
// } else {
//   render(
//     <BrowserRouter>
//       <React.StrictMode>
//         <App />
//       </React.StrictMode>
//     </BrowserRouter>,
//     rootElement,
//   );
// }

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);
