import React, { lazy, Suspense } from 'react';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Route, Switch } from 'react-router';
import { ThankYou } from '../../components/ThankYou';
import { Privacy } from '../../components/Privacy';
import { Helmet } from 'react-helmet';
import renderLoader from '../../components/renderLoader';
const Bar = lazy(() => import('../../components/Navbar/Bar'));
const Footer = lazy(() => import('../../components/Footer/Footer'));

const Product = lazy(() => import('../Product'));
const UseCases = lazy(() => import('../UseCases'));
const Compare = lazy(() => import('../Compare'));
const ContactForm = lazy(() => import('../../components/ContactForm'));
const About = lazy(() => import('../About'));
const Referral = lazy(() => import('../Referral'));
const Landing = lazy(() => import('../Landing'));
// import { Landing } from '../Landing';

// import { Referral } from '../Referral';
// import { ContactForm } from '../../components/ContactForm';
// import { Compare } from '../Compare';
// import { UseCases } from '../UseCases';
// import { Product } from '../Product';
// import { Footer } from '../../components/Footer/Footer';
// import { Bar } from '../../components/Navbar/Bar';
// import { About } from '../About';


// const routes = [
//   {
//     Component: Product,
//     path: "/product/",
//   },
//   {
//     Component: UseCases,
//     path: "/use-cases/",
//   },
//   {
//     Component: Compare,
//     path: "/compare/",
//   },
//   {
//     Component: ContactPage,
//     path: "/contact/",
//   },
//   {
//     Component: About,
//     path: "/about/",
//   },
//   {
//     Component: Referral,
//     path: "/refer-restaurants/"
//   },
//   {
//     Component: ThankYou,
//     path: "/thankyou/"
//   },
//   {
//     Component: Privacy,
//     path: "/privacy/"
//   },
//   {
//     Component: Landing,
//     path: "/"
//   }
// ]
// const PreloadLazyComponents = () => {
//   const [actPreload, setActPreload] = React.useState(true);
//   React.useEffect(() => {
//     const t = setTimeout(() => {
//       setActPreload(false);
//     }, 3000);
//     return () => {
//       clearTimeout(t);
//     };
//   });
//
//   if (actPreload)
//     return (
//       <div style={preloadStyles} hidden className={'absolute'}>
//         {routes.map(route => {
//           return <route.Component key={route.path} />;
//         })}
//       </div>
//     );
// };

// const preloadStyles = {
//   maxHeight: 0,
//   maxWidth: 0,
//   opacity: 0,
//   top: "-100%",
//   left: "-100%",
// }

function App() {
  return (
    <Suspense fallback={renderLoader()}>
      <div className={'overflow-x-hidden bg-gray-100'}>
        <div className="container mx-auto sm:pl-2 md:pl-4 lg:pl-8 xl:pl-16 2xl:pl-20">
          <Bar />
          <Switch>
            <Route
              path={'/product/'}
              render={() => {
                return <Product />;
              }}
            />
            <Route
              path={'/use-cases/'}
              render={() => {
                return <UseCases />;
              }}
            />
            <Route
              path={'/compare/'}
              render={() => {
                return <Compare />;
              }}
            />
            <Route
              path={'/contact/'}
              render={() => {
                return (
                  <>
                    {' '}
                    <Helmet>
                      <meta charSet="utf-8" />
                      <title>TableTab | Get Started in Minutes</title>
                      <meta name="robots" content="index, follow" />
                      <meta
                        name="description"
                        content="TableTab is a mobile restaurant dining system that helps restaurants operate the most personalized, safe, efficient, &amp; profitable dining experience available today."
                      />
                      <link
                        rel="canonical"
                        href="https://tabletab.io/contact/"
                      />
                    </Helmet>{' '}
                    <ContactForm className={'mt-20'} />{' '}
                  </>
                );
              }}
            />
            <Route
              path={'/about/'}
              render={() => {
                return <About />;
              }}
            />
            <Route
              path={'/refer-restaurants/'}
              render={() => {
                return <Referral />;
              }}
            />
            <Route
              path={'/thankyou/'}
              render={() => {
                return <ThankYou />;
              }}
            />
            <Route
              path={'/privacy/'}
              render={() => {
                return <Privacy />;
              }}
            />
            <Route
              path={'/'}
              exact
              render={() => {
                return <Landing />;
              }}
            />
          </Switch>
          <div className={'mt-60'}>
            <Footer />
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
