import React from 'react';
import { Helmet } from 'react-helmet';
export const Privacy: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TableTab | Privacy Policy</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="TableTab is a mobile restaurant dining system that helps restaurants operate the most personalized, safe, efficient, &amp; profitable dining experience available today."
        />
        <link rel="canonical" href="https://tabletab.io/privacy" />
      </Helmet>
      <div className={'text-left mt-20 lg:mt-32 2xl:mt-40'}>
        <h2 className="text-3xl tracking-tight font-bold  sm:text-3xl md:text-5xl">
          Privacy Policy
        </h2>
        <p className="px-0 mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          Updated: 10/26/20
        </p>

        <p
          className={
            'px-0 mt-10 text-base text-gray-500  sm:text-lg sm:max-w-xl sm:mx-auto lg:max-w-3/4 md:mt-16 md:text-xl lg:mx-0'
          }
        >
          Below is our Privacy Policy, which outline a lot of legal goodies. The
          bottom line is it’s our aim to always protect your privacy.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Intro
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          Dyne Technologies, Inc. built the TableTab app as a Free app. This
          SERVICE is provided by Dyne Technologies, Inc. at no cost and is
          intended for use as is.
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at TableTab unless otherwise
          defined in this Privacy Policy.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Information Collection and Use
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to First Name, Last Name, Phone Number,
          Dining History, Location History (with authorization), Venmo Username
          (to cash in reward points), Age, Dining Preferences, Contacts. . The
          information that we request will be retained by us and used as
          described in this privacy policy.
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          The app uses third party services that may collect information used to
          identify you, such as Firebase, Segment, Google Play Services, Heap,
          and Hubspot.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Log Data
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Cookies
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          This Service does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Service Providers
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>
        <ul className={'list-disc list-inside'}>
          <li
            className={
              ' px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
            }
          >
            To facilitate our Service;
          </li>
          <li
            className={
              ' px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
            }
          >
            To provide the Service on our behalf;
          </li>
          <li
            className={
              ' px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
            }
          >
            To perform Service-related services; or
          </li>
          <li
            className={
              ' px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
            }
          >
            To assist us in analyzing how our Service is used.
          </li>
        </ul>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Security
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Links to Other Sites
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Children’s Privacy
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          These Services do not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to do necessary actions.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Changes to This Privacy Policy
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          We may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. We will
          notify you of any changes by posting the new Privacy Policy on this
          page. These changes are effective immediately after they are posted on
          this page.
        </p>
        <p
          className={
            'px-0 mt-8 text-lg text-gray-700 sm:text-xl sm:max-w-xl sm:mx-auto  md:mt-10 md:text-2xl lg:mx-0'
          }
        >
          Contact Us
        </p>
        <p
          className={
            'px-0 mt-3 text-base text-gray-500  sm:text-lg sm:max-w-xl lg:max-w-3/4 sm:mx-auto md:mt-5 md:text-xl lg:mx-0'
          }
        >
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at contact@tabletab.io.
        </p>
      </div>
    </>
  );
};
