import React from 'react';
import { Helmet } from 'react-helmet';
type Props = {
  className?: string;
};

export const ThankYou: React.FC<Props> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>TableTab | Thank You</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="TableTab is a mobile restaurant dining system that helps restaurants operate the most personalized, safe, efficient, &amp; profitable dining experience available today."
        />
        <link rel="canonical" href="https://tabletab.io/thankyou" />
      </Helmet>
      <div className={'mt-20 md:mt-32 xl:mt-40 text-center'}>
        <h2 className="text-4xl tracking-tight font-bold  sm:text-3xl md:text-5xl">
          <span className="block text-primary-600">Thank You</span>
        </h2>
        <p className="px-0 mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          We have received your submission. We will be in touch shortly via text
          or email. Stay safe & stay hungry 😋
        </p>
      </div>
    </>
  );
};
